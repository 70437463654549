import axios from 'axios';

/* - utilização -
	Importotar antes arquivo

	import Resquest from '@/utils/Request.js';

	Execução da chamada
	let success = (response) => {
		console.log(error);
	}
	let error = (error) => {
		console.log(error);
	}
	let url = Request.mountUrl('/grupos');
	Request.get(url)
		.then(success)
		.catch(error);
*/

export default {

	// http://chamados.suporte.es/inma oficial
	// urlBase: 'http://chamadosapi.testeti.com:9903/v0.2',
	// urlBase: 'http://chamadosapi.testeti.com/v0.2',
	urlBase: 'https://glpi.suporte.es/apirest.php',
  
	urlBase2: 'http://escolas.suporte.es:5000',

	config: {
		headers: {
			'Accept-Language': 'pt-br'
		}
	},

	mountUrl(url = ''){
		// tratar diferentes ambientes ... process.env.NODE_ENV == development
		return this.urlBase + url;
	},

  mountUrl2(url = ''){
		// tratar diferentes ambientes ... process.env.NODE_ENV == development
		return this.urlBase2 + url;
	},

	get(url){
		return axios.get(url, this.config);
	},

	options(url){
		return axios.options(url, this.config);
	},



	getWithToken(url){
		let token = localStorage.getItem('token');
		let result = axios.get(url, {
			headers: {
				'Accept-Language': 'pt-br',
				'Authorization': 'bearer '+token,
			}
		});
		return result;
	},

	post(url, data){
		return axios.post(url, data, this.config);
	},

	optionsWithToken(url){
		let token = localStorage.getItem('token');
		let result = axios.options(url, {
			headers: {
				'Accept-Language': 'pt-br',
				'Authorization': 'bearer '+token
			}
		});
		return result;
	},

	postWithToken(url, data){
		let token = localStorage.getItem('token');
		let result = axios.post(url, data, {
			headers: {
				'Accept-Language': 'pt-br',
				'Authorization': 'bearer '+token
			}
		});
		return result;
	},

	put(url, data){
		return axios.put(url, data);
	},

	putWithToken(url, data){
		let token = localStorage.getItem('token');
		let result = axios.put(url, data, {
			headers: {
				'Accept-Language': 'pt-br',
				'Authorization': 'bearer '+token
			}
		});
		return result;
	},

	delete(url){
		let result = axios.delete(url);
		return result;
	},

	deleteWithToken(url){
		let token = localStorage.getItem('token');
		let result = axios.delete(url, {
			headers: {
				'Accept-Language': 'pt-br',
				'Authorization': 'bearer '+token
			}
		});
		return result;
	},

	getErrorMsg(error){
		if (error.response.data.errors[0].msg != null) {
			return error.response.data.errors[0].msg;
		} else {
			return 'Ocorreu algum erro!';
		}
	},

	postGLPI(url, data, config){
		return axios.post(url, data, config);
	},

	getGLPI(url, config){
		return axios.get(url, config);
	},

}

/*
	testessss
    axios.interceptors.request.use((config) => {
      tconfighis.isLoaderProcessos = true;
      console.log(config);
      return config;
    }, (error) => {
      this.isLoaderProcessos = false;
      return Promise.reject(error);
    });
    axios.interceptors.response.use((response) => {
      this.isLoaderProcessos = true;
      return response;
    }, (error) => {
      this.isLoaderProcessos = false;
      return Promise.reject(error);
    });
*/